



































import {
  DisplayTotal,
  FormHeader,
  FormTable,
} from "@/components/GoodsReceiptPrice";
import { useReceiveItem } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Mode } from "@/models/enums/global.enum";
import { LogisticReceivingItemStateEnum } from "@/models/enums/receive-items.enum";
import {
  ReceivingItemCreateRequestDto,
  ReceivingItemUpdateRequestDto,
} from "@/models/interface/receive-item";
import { FormValue } from "@/store/goodsReceiptPrice.store";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

/**
 * notes
 * - currency yang dikirim dalam bentuk CODE bukan secure id
 * - modal asset items sama kaya GR checklist
 * - ga perlu ada add row
 * - status yang terdaftar DRAFT / UNBILLED
 * - flow SUBMIT dan CREATE document nya sama dengan yang GR checklist

 * calculation formula
 *
 * -- DPP --
 * gross total = qty * unit price
 * gross total after disc = gross total - discount
 *
 * EXCLUSIVE
 * dpp = gross total after disc
 *
 * INCLUSIVE
 * dpp = gross total after disc / ((tax rate / 100) + 1)
 *
 * -- TAX VALUE (TAX) --
 * EXCLUSIVE
 * tax = dpp * (tax rate / 100)
 *
 * INCLUSIVE
 * tax = gross total after disc - dpp
 *
 * -- TOTAL --
 * total price = dpp + tax
 */

@Component({
  beforeRouteLeave(_to, _from, next) {
    this.resetStore();
    next();
  },
  components: {
    FormHeader,
    FormTable,
    DisplayTotal,
  },
  computed: {
    ...mapState({}),
    ...mapGetters({
      form: "goodsReceiptPriceStore/getForm",
    }),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      resetStore: "goodsReceiptPriceStore/resetStore",
    }),
  },
})
export default class FormPage extends Mixins(MNotificationVue) {
  form!: FormValue;
  resetStore!: () => void;

  @Ref("formHeader")
  formHeader!: any;

  @Prop({ required: false, type: String, default: "" })
  id!: string; // goods receipt secure id

  loading = {
    draft: false,
    submit: false,
    print: false,
  };

  get title(): string {
    const mode: Mode = this.$route.meta?.mode || "";
    const createStr = this.$t("lbl_create_x", {
      x: this.$t("lbl_goods_receipt_price"),
    });
    const editStr = this.$t("lbl_goods_receipt_price_edit");

    return mode === Mode.CREATE ? createStr.toString() : editStr.toString();
  }

  get isModeEdit(): boolean {
    const mode: Mode = this.$route.meta?.mode || "";
    return mode === Mode.EDIT;
  }

  get isModeCreate(): boolean {
    const mode: Mode = this.$route.meta?.mode || "";
    return mode === Mode.CREATE;
  }

  handleBack(): void {
    this.$router.push({
      name: "purchasing.goods-receipt-price",
    });
  }

  handleSaveDraft(): void {
    const { createDraft, mapFormPriceToCreateDto } = useReceiveItem();
    const req: ReceivingItemCreateRequestDto = mapFormPriceToCreateDto(
      this.form
    );
    this.loading.draft = true;
    createDraft(req)
      .then(() => {
        this.showNotifSuccess("notif_create_success");
        this.handleBack();
      })
      .finally(() => {
        this.loading.draft = false;
      });
  }

  validateForm(action: "draft" | "submit"): void {
    const form: FormModel = this.formHeader.formHeader;
    const { isRowInvalid } = useReceiveItem();
    form.validate((valid: boolean) => {
      if (!valid || isRowInvalid()) {
        this.showNotifWarning("notif_validation_error");
        return;
      }

      if (action === "submit") {
        if (this.isModeCreate) {
          this.handleSubmitNew();
        } else if (this.id && this.isModeEdit) {
          this.handleSubmit(this.id);
        }
      } else if (action === "draft") {
        this.handleSaveDraft();
      }
    });
  }

  handleSubmitNew(): void {
    const { create, mapFormPriceToCreateDto } = useReceiveItem();
    const req: ReceivingItemCreateRequestDto = mapFormPriceToCreateDto(
      this.form
    );

    // SET STATUS TO SUBMITTED
    req.status = LogisticReceivingItemStateEnum.SUBMITTED;
    //

    this.loading.submit = true;
    create(req)
      .then(({ documentNumber }) => {
        this.showNotifSuccess("notif_create_success", { documentNumber });
        this.handleBack();
      })
      .finally(() => {
        this.loading.submit = false;
      });
  }

  handleSubmit(docId: string): void {
    const { submitReceiveItem, mapFormPriceToUpdateDto } = useReceiveItem();
    const req: ReceivingItemUpdateRequestDto = mapFormPriceToUpdateDto(
      this.form
    );
    this.loading.submit = true;
    submitReceiveItem(docId, req)
      .then(({ documentNumber }) => {
        this.showNotifSuccess("notif_update_success", { documentNumber });
        this.handleBack();
      })
      .finally(() => {
        this.loading.submit = false;
      });
  }
}
